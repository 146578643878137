import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    cart(state, action) {
      const { items } = action.payload;
      state.items = items;
    }
  },
});

export const { cart } = cartSlice.actions;

export default cartSlice.reducer;
