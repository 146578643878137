import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./categorytitle.css";
import "../../index.css";
import { Col, Container, Row, Carousel } from "react-bootstrap";
import WelcomeVideo from "../../assets/video/Woman_in_the_pool.mp4";
import { useNavigate, useParams } from "react-router-dom";
import { getFormObj, server_domain } from "../../services/constants";
import { post } from "../../services/axios";
import { setError } from "../../redux/appsettingSlice";
import { favorite } from "../../redux/favoriteSlice";
import { AutoVideo } from "../../Components/AutoVideo";
import { currencyFormat } from "../../services/helper";

const CategoryTitle = () => {
  const params = useParams();
  const navigate = useNavigate();
  const categoryId = params.id;

  const user = useSelector((state) => state.auth.user);
  const whitelabel_id = useSelector((state) => state.appsetting.whitelabel_id);
  const user_id = user?.user_id;

  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (items.length > 0) {
      getFavorites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const updateItems = (favors) => {
    let tempItems = items;
    if (tempItems.length > 0) {
      tempItems.forEach((item) => {
        if (favors.length > 0) {
          let temp = null;
          favors.forEach((favor) => {
            if (item._id === favor.itemISbb_esp_supplied_itemsID) {
              temp = favor._id;
            }
          });
          item.itemISbb_esp_supplied_itemsID = temp;
        } else {
          item.itemISbb_esp_supplied_itemsID = null;
        }
      });
    }
    setItems(tempItems);
    dispatch(favorite({ counts: favors.length }));
  };

  const getItemImages = (item) => {
    let images = [];
    if (item.image01) {
      images = [...images, item.image01];
    }
    if (item.image02) {
      images = [...images, item.image02];
    }
    if (item.image03) {
      images = [...images, item.image03];
    }
    return images;
  };

  const getItems = async () => {
    let formData = getFormObj();
    formData.append("api_method", "list_items");
    formData.append("user_id", user_id);
    formData.append("whitelabel_id", whitelabel_id);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        const responseItems = response.data.items;
        let categoryItems = responseItems.filter(
          (item) => item.category_id === categoryId
        );

        setItems(categoryItems);
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            alert_title: response.data.alert_title,
            alert_text: response.data.alert_text,
            alert_action_button_title: response.data.alert_action_button_title,
            alert_action_url: response.data.alert_action_url,
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      });
    }
  };

  const getFavorites = async () => {
    let formData = getFormObj();
    formData.append("api_method", "list_app_favorites");
    formData.append("user_id", user_id);
    try {
      const response = await post(server_domain, formData);
      if (
        response.data.message === "SUCCESS" &&
        response.data.list.length > 0
      ) {
        const favor = response.data.list;
        setFavorites(favor);
        updateItems(favor);
      }
    } catch (err) {
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      });
    }
  };

  const addFavorite = async (productId) => {
    let formData = getFormObj();
    formData.append("api_method", "add_app_favorites");
    formData.append("user_id", user_id);
    formData.append("itemISbb_esp_supplied_itemsID", productId);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        const favor = [...favorites, response.data.item];
        setFavorites(favor);
        updateItems(favor);
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            alert_title: response.data.alert_title,
            alert_text: response.data.alert_text,
            alert_action_button_title: response.data.alert_action_button_title,
            alert_action_url: response.data.alert_action_url,
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      });
    }
  };

  const removeFavorite = async (favoriteId) => {
    let formData = getFormObj();
    formData.append("api_method", "delete_app_favorites");
    formData.append("user_id", user_id);
    formData.append("_id", favoriteId);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        const favor = favorites.filter((item) => item._id !== favoriteId);
        setFavorites(favor);
        updateItems(favor);
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            alert_title: response.data.alert_title,
            alert_text: response.data.alert_text,
            alert_action_button_title: response.data.alert_action_button_title,
            alert_action_url: response.data.alert_action_url,
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      });
    }
  };

  const reveal = () => {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  };
  reveal();
  window.addEventListener("scroll", reveal);

  const onFavorite = (e, favoriteId, productId) => {
    e.preventDefault();
    if (favoriteId) {
      removeFavorite(favoriteId);
    } else {
      addFavorite(productId);
    }
  };

  const gotoItem = (e, url) => {
    if (e.target.localName === "img" || e.target.localName === "h4") {
      navigate(url);
    }
  };

  return (
    <div>
      <div className="video-term reveal fade-in active">
        <AutoVideo className="headervideo" src={WelcomeVideo} />
        <Container></Container>
      </div>
      <div className="pt-5 position-relative">
        <Container>
          <div className="header-wrapper">
            <h1 className="header-text">{items[0]?.category}</h1>
          </div>
          <Row>
            {items.map((item) => (
              <Col lg="6" key={item._id}>
                <div
                  className="product-card"
                  onClick={(e) => gotoItem(e, `/item/${item._id}`)}
                >
                  <div className="badge-per">
                    {item.no_of_people}{" "}
                    {item.no_of_people === "1" ? "person" : "people"}
                  </div>
                  <div className="product-tumb">
                    <Carousel
                      fade
                      controls={getItemImages(item).length > 1}
                      indicators={getItemImages(item).length > 1}
                    >
                      {getItemImages(item).map((i, index) => (
                        <Carousel.Item key={index}>
                          <img
                            className="d-block w-100"
                            src={i}
                            alt={`Slide ${index}`}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                  <div className="product-details">
                    <h4>{item.name}</h4>
                    <div className="product-bottom-details">
                      <div className="product-price">
                        {/* <small className="special-price">&#163; 96.00</small> */}
                        {currencyFormat(item.priceNUM)}
                      </div>
                      <div className="product-links">
                        <div
                          onClick={(e) =>
                            onFavorite(
                              e,
                              item.itemISbb_esp_supplied_itemsID,
                              item._id
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill={
                              item.itemISbb_esp_supplied_itemsID
                                ? "#ff0000"
                                : "#ffffff"
                            }
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="product-title bottom-info"
                  onClick={(e) => gotoItem(e, `/item/${item._id}`)}
                >
                  <h4>{item.name}</h4>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CategoryTitle;
