import { useEffect, useState, useInsertionEffect } from "react";
import { BrowserRouter, Route, Routes  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { Header } from "./Header/header";
import Footer from "./Footer/footer";
// import Home from './Home'
import About from "./Pages/About/About";
import Login from "./Pages/Login/login";
import Signup from "./Pages/Signup/signup";
import ResetPassword from "./Pages/Resetpassword/resetpassword";
import TermsConditions from "./Pages/Termsconditions/termsconditions";
import Privacypolicy from "./Pages/Privacypolicy/privacypolicy";
import Givingback from "./Pages/Givingback/givingback";
import Welcome from "./Pages/Welcome/welcome";
import Categorytitle from "./Pages/Categorytitle/categorytitle";
import Productdetail from "./Pages/Productdetail/productdetail";
import Contactus from "./Pages/Contactus/contactus";
import Userprofile from "./Pages/Userprofile/userprofile";
import Yourorder from "./Pages/Yourorder/yourorder";
import Passreset from "./Pages/Passreset/passreset";
import SaveItem from "./Pages/Saveitem/saveitem";
import Orderdetails from "./Pages/Orderdetails/orderdetails";
import Payment from "./Pages/Payment/payment";
import Checkout from "./Pages/Checkout/checkout";
import Paid from "./Pages/Paid/paid";
import "bootstrap/dist/css/bootstrap.min.css";
import { login } from "./redux/authSlice";
import { cart } from "./redux/cartSlice";
import { favorite } from "./redux/favoriteSlice";
import { setInit } from "./redux/appsettingSlice";
import AuthRequire from "./services/AuthRequire";
import { getFormObj, server_domain } from "./services/constants";
import { post } from "./services/axios";
import { setError } from "./redux/appsettingSlice";
import ScrollToTop from "./services/ScrollToTop";

import { useRef } from 'react';

function App() {
   //const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [isLoaded, setIsLoaded] = useState(false);

  const dispatch = useDispatch();

  const app_bgcolor = useSelector(
    (state) => state.appsetting.app_bgcolor
  );
  const main_wrapper_ref = useRef(null);

  const getFavorites = async (user_id) => {
    let formData = getFormObj();
    formData.append("api_method", "list_app_favorites");
    formData.append("user_id", user_id);
    try {
      const response = await post(server_domain, formData);
      if (
        response.data.message === "SUCCESS" &&
        response.data.list.length > 0
      ) {
        const favorCnt = response.data.no_of_records;
        dispatch(favorite({ counts: favorCnt }));
      } else {
        dispatch(favorite({ counts: 0 }));
      }
    } catch (err) {
      dispatch(favorite({ counts: 0 }));
    }
  };

  const getOrdersItems = async (user_id) => {
    let formData = getFormObj();
    formData.append("api_method", "get_cart");
    formData.append("user_id", user_id);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        const ordersItems = response.data.cart.items || [];
        dispatch(cart({ items: ordersItems }));
      } else if (response.data.error) {
        dispatch(cart({ items: [] }));
      }
    } catch (err) {
      dispatch(cart({ items: [] }));
    }
  };

  if (!isAuthenticated) {
    // when redux store is initialized but data in localstorage is still alive, then get data from
    // localstorage and restructure the redux store auth data.
    let isAuthStorage = localStorage.getItem("isAuthenticated");
    if (isAuthStorage === "done") {
      let user = JSON.parse(localStorage.getItem("user"));
      dispatch(
        login({
          isAuthenticated: true,
          user: user,
        })
      );
      getOrdersItems(user.user_id);
      getFavorites(user.user_id);
    }
  }

  const handlerAppSettingData = async () => {
    setIsLoaded(false);
    let formData = getFormObj();
    formData.append("api_method", "app_settings");
    formData.append("app_version", "1");

    const device_info = {
      "navigator.userAgent": navigator.userAgent,
      "window.location.href": window.location.href,
    };
    formData.append("device_info", JSON.stringify(device_info));

    try {
      var response = await post(server_domain, formData);

      //response['data']['app_settings']['whitelabel_bg_color']='#061747';

      const data = response.data;
      if (data.error) {
        dispatch(
          setError({
            isShow: true,
            alert_title: response.data.alert_title,
            alert_text: response.data.alert_text,
            alert_action_button_title: response.data.alert_action_button_title,
            alert_action_url: response.data.alert_action_url,
            message: response.data.message,
          })
        );
        return;
      }

      console.log("app_settings response", response.data);

      const whitelabel_id = data.app_settings.whitelabel_id;

      const settingDatas = data.app_settings.content;
      const login_content = settingDatas.find(
        (settingData) => settingData.code === "LOGIN_PAGE"
      );
      const signup_content = settingDatas.find(
        (settingData) => settingData.code === "SIGNUP_PAGE"
      );
      const password_reset_content = settingDatas.find(
        (settingData) => settingData.code === "PASSWORD_RESET_PAGE"
      );
      const welcome_content = settingDatas.find(
        (settingData) => settingData.code === "WELCOME_PAGE"
      );
      const about_content = settingDatas.find(
        (settingData) => settingData.code === "ABOUT_PAGE"
      );
      const terms_content = settingDatas.find(
        (settingData) => settingData.code === "TERMS_PAGE"
      );
      const privacy_policy_content = settingDatas.find(
        (settingData) => settingData.code === "PRIVACY_PAGE"
      );
      const contact_us_content = settingDatas.find(
        (settingData) => settingData.code === "CONTACT_PAGE"
      );
      const giving_back_content = settingDatas.find(
        (settingData) => settingData.code === "GIVING_BACK"
      );
      const logo = settingDatas.find(
        (settingData) => settingData.code === "LOGO"
      );
      
      const app_bgcolor = data.app_settings.whitelabel_bg_color;

      const charities = data.app_settings.charities;
      const isAppsettingsLoaded = true;

      dispatch(
        setInit({
          login_content,
          signup_content,
          password_reset_content,
          welcome_content,
          about_content,
          terms_content,
          privacy_policy_content,
          contact_us_content,
          giving_back_content,
          logo,
          charities,
          whitelabel_id,
          isAppsettingsLoaded,
          app_bgcolor,
        })
      );
      setIsLoaded(true);
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          alert_title: "Error",
          alert_text: "",
          alert_action_button_title: "",
          alert_action_url: "",
          message: err.toString(),
        })
      );
      setIsLoaded(true);
    }
  };

  useInsertionEffect(() => {
    handlerAppSettingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if(isLoaded){
      main_wrapper_ref.current.style.backgroundColor=app_bgcolor;
      main_wrapper_ref.current.style.minHeight='100vh';
    }
  }, [isLoaded,app_bgcolor,main_wrapper_ref]);


  return (
    <>
      {isLoaded ? (
        <BrowserRouter>
          <ScrollToTop />
          <div ref={main_wrapper_ref}>
          <Header />
          
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path="/giving-back" element={<Givingback />} />
            <Route path="/contact-us" element={<Contactus />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route
              path="/category/:id"
              element={
                <AuthRequire>
                  <Categorytitle />
                </AuthRequire>
              }
            />
            <Route
              path="/item/:id"
              element={
                <AuthRequire>
                  <Productdetail />
                </AuthRequire>
              }
            />
            <Route
              path="/user-profile"
              element={
                <AuthRequire>
                  <Userprofile />
                </AuthRequire>
              }
            />
            <Route
              path="/your-order"
              element={
                <AuthRequire>
                  <Yourorder />
                </AuthRequire>
              }
            />
            <Route path="/pass-reset" element={<Passreset />} />
            <Route
              path="/save-item"
              element={
                <AuthRequire>
                  <SaveItem />
                </AuthRequire>
              }
            />
            <Route
              path="/order-details"
              element={
                <AuthRequire>
                  <Orderdetails />
                </AuthRequire>
              }
            />
            <Route
              path="/payment"
              element={
                <AuthRequire>
                  <Payment />
                </AuthRequire>
              }
            />
            <Route
              path="/checkout"
              element={
                <AuthRequire>
                  <Checkout />
                </AuthRequire>
              }
            />
            <Route
              path="/paid"
              element={
                <AuthRequire>
                  <Paid />
                </AuthRequire>
              }
            />
          </Routes>
          
          <Footer />
          </div>
        </BrowserRouter>
      ) : (
        <></>
      )}
    </>
  );
}

export default App;
