export const currencyFormat = (num) => {
  const result = (num) ? '£ '+ Math.round(num).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '£ 0'
  return result
};


export const htmlString = (inputStr) => {
  if (inputStr) {
    return inputStr.replace(/\r\n\r\n/g, '<br>');
  }
  return inputStr
};
