import React from "react";

export const AutoVideo = (props) => {
  const { src, className } = props;
  
  return (
    <video loop muted autoPlay playsInline className={className} key={src}>
      <source src={src} type="video/mp4"></source>
    </video>
  );
};
