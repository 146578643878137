import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./welcome.css";
import "../../index.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFormObj, server_domain } from "../../services/constants";
import { post } from "../../services/axios";
import { setError } from "../../redux/appsettingSlice";
import { AutoVideo } from "../../Components/AutoVideo";
import { BiDownArrowAlt } from "react-icons/bi";

const Welcome = () => {
  const dispatch = useDispatch();
  const welcomeContent = useSelector(
    (state) => state.appsetting.welcome_content
  );
  const whitelabel_id = useSelector((state) => state.appsetting.whitelabel_id);
  const isAppsettingsLoaded = useSelector(
    (state) => state.appsetting.isAppsettingsLoaded
  );
  const headerVideo = welcomeContent.header_videoISfile;

  const title = welcomeContent.name;
  const subTitle = welcomeContent.main_subheading;

  const user = useSelector((state) => state.auth.user);
  const user_id = user?.user_id;

  const categoriesRef = useRef(null);

  const reveal = () => {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  };
  reveal();
  window.addEventListener("scroll", reveal);

  const [categories, setCategories] = useState([]);

  const scrollToBottom = () => {
    categoriesRef.current.scrollIntoView();
  };

  useEffect(() => {
    const getCategories = async () => {
      let formData = getFormObj();
      formData.append("api_method", "list_categories");
      formData.append("user_id", user_id);
      formData.append("whitelabel_id", whitelabel_id);

      console.log("isAppsettingsLoaded:", isAppsettingsLoaded);
      console.log("whitelabel_id", whitelabel_id);

      try {
        const response = await post(server_domain, formData);
        console.log("Response of list_categories", response);
        if (response.data.message === "SUCCESS") {
          setCategories(response.data.categories);
        } else if (response.data.error) {
          dispatch(
            setError({
              isShow: true,
              alert_title: response.data.alert_title,
              alert_text: response.data.alert_text,
              alert_action_button_title:
                response.data.alert_action_button_title,
              alert_action_url: response.data.alert_action_url,
              message: response.data.message,
            })
          );
        }
      } catch (err) {
        dispatch(
          setError({
            isShow: true,
            alert_title: "Error",
            alert_text: "",
            alert_action_button_title: "",
            alert_action_url: "",
            message: err.toString(),
          })
        );
      }
    };

    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  return (
    <div>
      <div className="welcome-term reveal fade-in active">
        <AutoVideo className="headervideo" src={headerVideo} />
      </div>
      <div className="pt-5">
        <Container>
          <div className="header-wrapper">
            <div className="header-text">
              <h1 className="header-title">{title}</h1>
              <p className="header-sub-title">{subTitle}</p>
            </div>
            <div className="arrow-down" onClick={scrollToBottom}>
              <BiDownArrowAlt size={"40px"} color={"#fff"} />
            </div>
          </div>
          <Row ref={categoriesRef}>
            {categories.map((category) => (
              <Col lg="6" key={category._id}>
                <Link to={`/category/${category._id}`}>
                  <div className="content">
                    <div className="grid">
                      <figure className="effect-layla">
                        <img
                          src={category.imageISfile}
                          alt="Product"
                          width={520}
                          style={{ objectFit: "cover" }}
                        />
                        <figcaption>
                          <h2>{category.name}</h2>
                          <p>{category.textISsmallplaintextbox}</p>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Welcome;
