import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  counts: 0,
};

const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    favorite(state, action) {
      const { counts } = action.payload;
      state.counts = counts;
    }
  },
});

export const { favorite } = favoriteSlice.actions;

export default favoriteSlice.reducer;
