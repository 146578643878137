import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFormObj, server_domain } from "../../services/constants";
import { post } from "../../services/axios";
import { setError } from "../../redux/appsettingSlice";
import './yourorder.css'
import '../../index.css'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Order from "./Order/order"
import { currencyFormat } from "../../services/helper"

const Yourorder = () => {
  const user = useSelector((state) => state.auth.user);
  const user_id = user?.user_id;
  const [orderItems, setOrderItems] = useState([]);
  const [credit, setCredit] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    loadProfile();
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProfile = async () => {
    let formData = getFormObj();
    formData.append("api_method", "load_profile");
    formData.append("user_id", user_id);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        const { credit } = response.data.user;
        setCredit(credit)
      } else if (response.data.error) {
        submitError(response);
      }
    } catch (err) {
      submitFailed(err);
    }
  };

  const submitError = (response) => {
    dispatch(
      setError({
        isShow: true,
        alert_title: response.data.alert_title,
        alert_text: response.data.alert_text,
        alert_action_button_title:
          response.data.alert_action_button_title,
        alert_action_url: response.data.alert_action_url,
        message: response.data.message,
      })
    );
  }
  
  const submitFailed = (err) => {
    dispatch(
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      })
    );
  }

  const getOrders = async () => {
    let formData = getFormObj();
    formData.append("api_method", "get_orders");
    formData.append("user_id", user_id);

    try {
      const response = await post(server_domain, formData);      
      if (response.data.message === "SUCCESS") {
        setOrderItems([]);
        const response_orders = Object.entries(response.data.orders).map(([name, obj]) => ({ name, ...obj }));
        let newItems = [];
        if (response_orders.length > 0) {          
          response_orders.forEach((o, oi) => {
            const items = o.items;
            if (items.length > 0) {
              items.forEach((i, ii) => {
                const newItem = {
                  key: `${oi}_${o._id}_${ii}`,
                  date: o.transaction_date,
                  orderId: o._id,
                  name: i.item_name,
                  qty: i.qtyNUM,
                  voucher: i.voucher_code,
                  voucherKey: i.voucher_key,
                  value: i.total_priceNUM,
                };
                newItems = [...newItems, newItem]
              });
            }
          });
        }
        setOrderItems(newItems)
      } else if (response.data.error) {
        submitError(response);
      }
    } catch (err) {
      submitFailed(err);
    }
  };

  return (
    <div>
      <div className='position-relative'>
        <Container className='pt-5'>
          <div className="header-wrapper no-video">
            <h1 className="header-text">Your Account</h1>
          </div>
          {credit > 0 && (
            <div className="credit-wrapper">
              <p className="credit">{`You have ${currencyFormat(credit)} credit in your account.`}</p>
            </div>
          )}
          
          <nav className="nav nav-borders">
            <Link to="/your-order" className="nav-link active ms-0">
              Orders
            </Link>
            <Link to="/save-item" className="nav-link">
              Favourites
            </Link>
            <Link to="/user-profile" className="nav-link">
              Profile
            </Link>
          </nav>
          <hr className="mt-0 mb-4" />                    
          <div className="card mb-4" >
            <div className="card-header" >Order History</div>
            <div className="card-body p-0" >
              <div className="table-responsive table-billing-history">
                <div>
                  <Order items={orderItems}/>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Yourorder