import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import './checkoutform.css'
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { getFormObj, server_domain } from "../../../services/constants";
import { post } from "../../../services/axios";
import { setError } from "../../../redux/appsettingSlice";
import { cart } from "../../../redux/cartSlice";
import { currencyFormat } from "../../../services/helper";

const iframeStyles = {
    base: {
      color: "#fff",
      fontSize: "16px",
      iconColor: "#fff",
      "::placeholder": {
        color: "#a1a1a1"
      },
      ':-webkit-autofill': {
        color: "#fff",
      }
    },
    invalid: {
      iconColor: "#dc3545",
      color: "#dc3545"
    },
    complete: {
      iconColor: "#fff"
    }
};
const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true
};

const CheckoutForm = (props) => {
    const {
        amount,
        clientSecret,
        storedCard,
        onSuccessfulCheckout,
    } = props

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const user_id = user?.user_id;

    const [usecard, setUsecard] = useState(false)
    const [name, setName] = useState('')
    const [remember, setRemember] = useState(true)

    const stripe = useStripe();
    const elements = useElements();

    const [isProcessing, setProcessingTo] = useState(false);
    const [checkoutError, setCheckoutError] = useState();

    const handleCardDetailsChange = ev => {
        ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    }

    const callCapturePayment = async (stripe_ref, cart_amount) => {
        
        let formData = getFormObj();
        formData.append("api_method", "capture_payment");
        formData.append("user_id", user_id);
        formData.append("stripe_ref", stripe_ref);
        formData.append("amount", cart_amount);
        formData.append("remember_me", remember);

        try {
            const response = await post(server_domain, formData);
            if (response.data.message === "SUCCESS") {
                onSuccessfulCheckout(response.data.order_complete);
                dispatch(
                    cart({items: []})
                );
                setProcessingTo(false);
            } else if (response.data.error) {
              dispatch(
                setError({
                  isShow: true,
                  alert_title: response.data.alert_title,
                  alert_text: response.data.alert_text,
                  alert_action_button_title:
                    response.data.alert_action_button_title,
                  alert_action_url: response.data.alert_action_url,
                  message: response.data.message,
                })
              );
              setProcessingTo(false);
            }
        } catch (err) {
            setError({
              isShow: true,
              alert_title: "Error",
              alert_text: "",
              alert_action_button_title: "",
              alert_action_url: "",
              message: err.toString(),
            });
            setProcessingTo(false);
        }
    }

    const doPayment = async (clientSecret) => {        
        const cardElement = elements.getElement("card");
        const billingDetails = {                    
            name: name
        };
        const paymentMethodReq = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: billingDetails
        });

        if (paymentMethodReq.error) {
            setCheckoutError(paymentMethodReq.error.message);
            setProcessingTo(false);
            return;
        }
    
        const response = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethodReq.paymentMethod.id
        });

        if (response.paymentIntent && response.paymentIntent.status === "succeeded") {
            const stripeRef = response.paymentIntent.id
            callCapturePayment(stripeRef, amount)
        } else {
            console.log(response)
            if (response.error) {
                setCheckoutError(response.error.message);
                setProcessingTo(false);
                return;
            }
            setCheckoutError("Failed to make the payment");
            setProcessingTo(false);
            return;
        }
    }

    const handleFormSubmit = async ev => {
        ev.preventDefault();
    
        setProcessingTo(true);

        if (usecard && storedCard) {
            callCapturePayment(storedCard, amount)
        } else {
            doPayment(clientSecret);
        }
        
    };

    const handleCardInfo = (event) => {
        const fname = event.target.name
        const value = event.target.value
        if (fname === 'usecard') {
            setUsecard(value)
        } else if (fname === 'name') {
            setName(value)
        }else if (fname === 'remember') {
            setRemember(value)
        }
    }

    return (
        <form className="needs-validation" onSubmit={handleFormSubmit}>
            {storedCard && (
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="remember-me form-group">
                            <input type="checkbox" name="usecard" id="usecard" value={usecard} onChange={handleCardInfo} />
                            <label htmlFor="usecard">{storedCard}</label>
                        </div>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label htmlFor="cc-name">Name on card</label>
                    <input type="text" className="form-control" name="name" id="cc-name" placeholder="" required value={name} onChange={handleCardInfo} />
                    <small className="text-muted">Full name as displayed on card</small>                    
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <label htmlFor="cc-number">Card Info</label>
                    <CardElement options={cardElementOpts} onChange={handleCardDetailsChange} className="form-control"/>
                    {checkoutError && (
                        <div className="text-danger">
                            {checkoutError}
                        </div>
                    )}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='payment-total'>
                        <div className="amount">Total to Pay: {currencyFormat(amount)}</div>
                        <div className="remember-me form-group">
                            <input type="checkbox" name="remember" id="remember" value={remember} onChange={handleCardInfo} />
                            <label htmlFor="remember">Store my card with Stripe.com for future payments</label>
                        </div>
                        <div className='text-center'>
                            <button className="btn btn-lg btn-block cont-shop" type="submit" disabled={isProcessing || !stripe || !clientSecret}>
                                {isProcessing ? "Processing..." : "Make Payment"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default CheckoutForm