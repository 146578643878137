import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./footer.css";
import "../index.css";
import { Link, useLocation } from "react-router-dom";
import Facebook from "../assets/image/facebook.png";
import Instagram from "../assets/image/instagram.png";
import Whatsapp from "../assets/image/whatsapp.png";
import { Container } from "react-bootstrap";
import { facebook_link, instagram_link, phone_number, whatsapp_message, bluebox_url } from "../services/constants";
import ReactWhatsapp from 'react-whatsapp';

import { useRef } from 'react';

const Footer = () => {
  const LogoContent = useSelector(
    (state) => state.appsetting.logo
  );

  const app_bgcolor = useSelector(
    (state) => state.appsetting.app_bgcolor
  );

  const logo = LogoContent.image01ISfile;
  const location = useLocation();
  const [page, setPage] = useState(false);
  const [isItem, setIsItem] = useState(false);

  const footer_ref = useRef(null);

  useEffect(() => {    

    footer_ref.current.style.backgroundColor=app_bgcolor;

    setIsItem(location.pathname.includes("/item/"))
    if (
      location.pathname === "/signup" ||
      location.pathname === "/login" ||
      location.pathname === "/resetpassword"
    ) {
      setPage(true);
    }
  }, [location,app_bgcolor]);

  const reveal = () => {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  };
  reveal();
  window.addEventListener("scroll", reveal);

  return (
    <div className={` ${page === true ? "footer" : "footer-maindiv"} `} ref={footer_ref}>
      <Container>
        <div>
          <ul className="footer-menu">
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/about">
              <li>About</li>
            </Link>
            <Link to="/contact-us">
              <li>Contact Us</li>
            </Link>
          </ul>
          <ul className={`social-icon ${isItem === true ? "hidden" : ""}`}>
            <ReactWhatsapp number={phone_number} message={whatsapp_message} className="whatsapp">
              <li>
                <img src={Whatsapp} alt="Whatsapp"/>
              </li>
            </ReactWhatsapp>
            <a href={facebook_link} target="_blank" rel="noreferrer">
              <li>
                <img src={Facebook} alt="Facebook" />
              </li>
            </a>
            <a href={instagram_link} target="_blank" rel="noreferrer">
              <li>
                <img src={Instagram} alt="Instagram" />
              </li>
            </a>
          </ul>
          <div className="term-footer">
            <p className="copy-text">(C) 2022 Exceed Experiences</p>
            <div className={`terms-social ${isItem === true ? "hidden" : ""}`}>
              <ul className="footer-menu">                
                <Link to="/terms-conditions">
                  <li>Terms and Conditions</li>
                </Link>
                <Link to="/">
                  <li>-</li>
                </Link>
                <Link to="/privacy-policy">
                  <li>Privacy Policy</li>
                </Link>                
              </ul>
            </div>

            <div className="footer-logo">
              <Link to="/">
                {logo && (
                  <img src={logo} alt="Footer Logo" />
                )}
              </Link>
            </div>
          </div>
          <div className="site_link">
            <a href={bluebox_url} target="_blank" rel="noreferrer">App Developed by BlueBox</a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
