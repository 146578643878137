import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";

import './payment.css'
import { Col, Container, Row } from 'react-bootstrap'
import TermsconditionsVIdeo from '../../assets/video/Man_walking_to_his_villa.mp4'
import { BiCheckCircle } from "react-icons/bi";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { getFormObj, server_domain } from "../../services/constants";
import { post } from "../../services/axios";
import { setError } from "../../redux/appsettingSlice";
import CheckoutForm from "./CheckoutForm/checkoutform";
import { AutoVideo } from "../../Components/AutoVideo";

const Payment = () => {

    const user = useSelector((state) => state.auth.user);
    const user_id = user?.user_id;
    const dispatch = useDispatch();

    const [amount, setAmount] = useState(0)
    const [clientSecret, setClientSecret] = useState('')
    const [storedCard, setStoredCard] = useState('')
    const [orderCompleteMessage, setOrderCompleteMessage] = useState('')
    const [stripePromise, setStripePromise] = useState(null)

    useEffect(() => {
      loadProfile()
      getOrdersItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadProfile = async () => {
      let formData = getFormObj();
      formData.append("api_method", "load_profile");
      formData.append("user_id", user_id);
  
      try {
        const response = await post(server_domain, formData);
        if (response.data.message === "SUCCESS") {
          const { stripe_public_key } = response.data.user;
          setStripePromise(loadStripe(stripe_public_key))
          
        } else if (response.data.error) {
          dispatch(
            setError({
              isShow: true,
              alert_title: response.data.alert_title,
              alert_text: response.data.alert_text,
              alert_action_button_title:
                response.data.alert_action_button_title,
              alert_action_url: response.data.alert_action_url,
              message: response.data.message,
            })
          );
        }
      } catch (err) {
        dispatch(
          setError({
            isShow: true,
            alert_title: "Error",
            alert_text: "",
            alert_action_button_title: "",
            alert_action_url: "",
            message: err.toString(),
          })
        );
      }
    };

    const getOrdersItems = async () => {
        let formData = getFormObj();
        formData.append("api_method", "get_cart");
        formData.append("user_id", user_id);
    
        try {
          const response = await post(server_domain, formData);
          if (response.data.message === "SUCCESS") {
            setAmount(response.data.cart.amount_to_pay);
            stripeInit(response.data.cart.amount_to_pay)
          } else if (response.data.error) {
            dispatch(
              setError({
                isShow: true,
                alert_title: response.data.alert_title,
                alert_text: response.data.alert_text,
                alert_action_button_title:
                  response.data.alert_action_button_title,
                alert_action_url: response.data.alert_action_url,
                message: response.data.message,
              })
            );
          }
        } catch (err) {
          setError({
            isShow: true,
            alert_title: "Error",
            alert_text: "",
            alert_action_button_title: "",
            alert_action_url: "",
            message: err.toString(),
          });
        }
    };

    const stripeInit = async (amount) => {
        let formData = getFormObj();
        formData.append("api_method", "stripe_init");
        formData.append("user_id", user_id);
        formData.append("amount", amount);

        try {
            const response = await post(server_domain, formData);
            if (response.data.message === "SUCCESS") {
                setClientSecret(response.data.clientSecret);
                setStoredCard(response.data.stored_card);
              
            } else if (response.data.error) {
              dispatch(
                setError({
                  isShow: true,
                  alert_title: response.data.alert_title,
                  alert_text: response.data.alert_text,
                  alert_action_button_title:
                    response.data.alert_action_button_title,
                  alert_action_url: response.data.alert_action_url,
                  message: response.data.message,
                })
              );
            }
        } catch (err) {
            setError({
              isShow: true,
              alert_title: "Error",
              alert_text: "",
              alert_action_button_title: "",
              alert_action_url: "",
              message: err.toString(),
            });
        }
    }

    return (
        <>
            {!orderCompleteMessage && (
                <Elements stripe={stripePromise}>
                    <div className='video-term reveal fade-in active'>
                        <AutoVideo className="headervideo" src={TermsconditionsVIdeo}/>
                    </div>
                    <div className='position-relative payment-completed'>
                        <Container className='mt-5'>
                            <div className='header-maindiv'>
                                <h1 className='header-payment'>Payment</h1>
                            </div>
                            <Row>
                                <Col lg="8" className='enter-detail'>
                                    <CheckoutForm
                                        amount={amount}
                                        clientSecret={clientSecret}
                                        storedCard={storedCard}
                                        onSuccessfulCheckout={(m) => setOrderCompleteMessage(m)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Elements>
            )}
            
            {orderCompleteMessage && (
                <>
                    <div className='video-term reveal fade-in active'>
                        <AutoVideo className="headervideo" src={TermsconditionsVIdeo}/>
                    </div>
                    <div className='position-relative payment-completed'>
                        <Container className='mt-5'>
                            <div className='header-maindiv'>
                                <div className='header-payment'>
                                    <div className='icon'>
                                        <BiCheckCircle size="80" color='#eed996'/>
                                    </div>
                                    <h1 className='title'>Payment Complete</h1>
                                </div>
                            </div>
                            <div className='confirm-detail' dangerouslySetInnerHTML={{ __html: orderCompleteMessage }}></div>
                        </Container>
                    </div>
                </>
            )}
        </>
    )

}

export default Payment