import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import appSettingReducer from "./appsettingSlice";
import cartSlice from "./cartSlice";
import favoriteSlice from "./favoriteSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appsetting: appSettingReducer,
    cart: cartSlice,
    favorite: favoriteSlice
  },
});
