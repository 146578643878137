import React from "react";
import { useSelector } from "react-redux";
import "./About.css";
import "../../index.css";
import { Col, Container, Row } from "react-bootstrap";
import { AutoVideo } from "../../Components/AutoVideo";
import { htmlString } from "../../services/helper";

const About = () => {
  const aboutContent = useSelector((state) => state.appsetting.about_content);
  const headerVideo = aboutContent.header_videoISfile;
  const mainSubHeading = aboutContent.main_subheading;
  const mainText = htmlString(aboutContent.main_textISsmallplaintextbox);
  const secondSubHeading = aboutContent.secondary_subheading;
  const secondText = htmlString(aboutContent.secondary_textISsmallplaintextbox);
  const image1 = aboutContent.image01ISfile;
  const image2 = aboutContent.image02ISfile;
  const image3 = aboutContent.image03ISfile;

  const reveal = () => {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  };
  reveal();
  window.addEventListener("scroll", reveal);
  return (
    <div>
      <div className="video-term reveal fade-in active">        
        <AutoVideo className="headervideo" src={headerVideo}/>
      </div>
      <div className="pt-5">
        <Container className="position-relative">
          <div className="header-wrapper">
            <h1 className="header-text">About</h1>
          </div>
          <Row className="justify-content-md-center mt-5">
            <Col lg="8">
              <div className="first-row">
                <div className="maindiv-image">
                  <div className="image-div mb-4">
                    <img src={image2} alt="charbel aoun 1IdM3Go0VSU unsplash" />
                  </div>
                  <div className="image-div mb-4">
                    <img src={image1} alt="cory bjork D1yT791Nf9A unsplash" />
                  </div>
                </div>
                <div className="image-text">
                  <h1>{mainSubHeading}</h1>
                  <div
                    dangerouslySetInnerHTML={{__html: mainText}}
                  />
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div className="">
                <div className="">
                  <div className="side-blog">
                    <img src={image3} alt="mar cerdeira bBF9e2UUh88 unsplash" />
                  </div>
                </div>
                <div className="side-text">
                  <h1>{secondSubHeading}</h1>
                  <div
                    dangerouslySetInnerHTML={{__html: secondText}}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default About;
