import React from "react";
import { useSelector, useDispatch } from "react-redux";
import "./header.css";
import { logout } from "../redux/authSlice";
import { cart } from "../redux/cartSlice";
import { Link } from "react-router-dom";
import { Container, Navbar, Offcanvas, Nav } from "react-bootstrap";
import { getFormObj, server_domain } from "../services/constants";
import { post } from "../services/axios";
import { AlertModal } from "../Components/AlertModal";
import { setError } from "../redux/appsettingSlice";
import { BiCart } from "react-icons/bi";

import { useRef } from 'react';
import { useEffect } from 'react';

export const Header = () => {
  const LogoContent = useSelector((state) => state.appsetting.logo);

  const app_bgcolor = useSelector((state) => state.appsetting.app_bgcolor);

  let red = parseInt(app_bgcolor.substring(1, 3), 16);
  let green = parseInt(app_bgcolor.substring(3, 5), 16);
  let blue = parseInt(app_bgcolor.substring(5, 7), 16);
  let rgba  = `rgba(${red}, ${green}, ${blue}, 0.5)`;

  const logo = LogoContent.image01ISfile;
  const error = useSelector((state) => state.appsetting.error);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const cartItems = useSelector((state) => state.cart.items);
  const favoriteCounts = useSelector((state) => state.favorite.counts);
  const user = useSelector((state) => state.auth.user);
  const user_id = user?.user_id;
  
  const dispatch = useDispatch();

  const navbar_ref = useRef(null);
  useEffect(() => {
    navbar_ref.current.style.backgroundColor=rgba;
  }, [navbar_ref,rgba]);

  const handlerLogout = async () => {
    let formData = getFormObj();
    formData.append("api_method", "logoff");
    formData.append("user_id", user_id);

    try {
      const logoutResponse = await post(server_domain, formData);

      if (logoutResponse.data.success === 1) {
        dispatch(logout());
        localStorage.clear();
        dispatch(
          setError({
            isShow: true,
            alert_title: "",
            alert_text: logoutResponse.data.alert_text,
            alert_action_button_title: "",
            alert_action_url: "",
            message: logoutResponse.data.alert_title,
          })
        );
      } else if (logoutResponse.data.error) {
        dispatch(
          setError({
            isShow: true,
            alert_title: "Error",
            alert_text: "",
            alert_action_button_title: "",
            alert_action_url: "",
            message: logoutResponse.data.error,
          })
        );
      }
    } catch (err) {
      dispatch(
        setError({
          isShow: true,
          alert_title: "Error",
          alert_text: "",
          alert_action_button_title: "",
          alert_action_url: "",
          message: err.toString(),
        })
      );
    }
    dispatch(logout());
    dispatch(
      cart({items: []})
    );
    localStorage.clear();
  };

  const onModalClosed = () => {
    dispatch(
      setError({
        isShow: false,
        alert_title: "",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: "",
      })
    );
  };

  

  return (
    <>
      <Navbar
        expand={"false"}
        fixed="top"
        collapseOnSelect={true}
        className="background-black"
        ref={navbar_ref}
      >
        <Container fluid>
          <Link to="/" className="header-logo">
            {logo && (
              <img src={logo} alt="Header Logo" />
            )}
          </Link>
          <div>
            {favoriteCounts > 0 && (
              <span className="top-fav">
                <span className="fav-cnt">{favoriteCounts}</span>
                <Link to="/save-item" className="favorite-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="30px"
                    viewBox="0 0 24 24"
                    width="30px"
                    fill={'#ffffff'}
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
                  </svg>
                </Link>
              </span>
            )}
            {cartItems && cartItems.length > 0 && (
              <Link to="/checkout" className="checkout-link">
                <BiCart size={"30px"} color={"#fff"}/>
              </Link>
            )}
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} />
          </div>
          <Navbar.Offcanvas
            // collapseOnSelect={false}
            id={`offcanvasNavbar-expand-false`}
            aria-labelledby={`offcanvasNavbarLabel-expand-false`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                                Offcanvas
                            </Offcanvas.Title> */}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="#" as={Nav}>
                  <Link to="/" className="nav-menulist">
                    Home
                  </Link>
                </Nav.Link>
                <Nav.Link href="#" as={Nav}>
                  <Link to="/about" className="nav-menulist">
                    About
                  </Link>
                </Nav.Link>
                <Nav.Link href="#" as={Nav}>
                  <Link to="/contact-us" className="nav-menulist">
                    Contact Us
                  </Link>{" "}
                </Nav.Link>
                <Nav.Link href="#" as={Nav}>
                  <Link to="/giving-back" className="nav-menulist">
                    Giving Back
                  </Link>{" "}
                </Nav.Link>
                {!isAuthenticated && (
                  <Nav.Link href="#" as={Nav}>
                    <Link to="/signup" className="nav-menulist">
                      Sign up
                    </Link>{" "}
                  </Nav.Link>
                )}
                {isAuthenticated && cartItems && cartItems.length > 0 && (
                  <Nav.Link href="#" as={Nav}>
                    <Link to="/checkout" className="nav-menulist">
                      Checkout
                    </Link>{" "}
                  </Nav.Link>
                )}
                {isAuthenticated && (
                  <Nav.Link href="#" as={Nav}>
                    <Link to="/your-order" className="nav-menulist">
                      Your Account
                    </Link>{" "}
                  </Nav.Link>
                )}                
                {isAuthenticated && (
                  <a 
                  className="nav-menulist" 
                  href={"https://exceed.blueboxonline.com/?sso&travel_unlok=1&email="+user.email} 
                  target="_blank"
                  rel="noreferrer"
                  >
                      Unlok Travel
                  </a>
                )}
                {isAuthenticated && (
                  <Nav.Link href="#" as={Nav}>
                    <Link
                      to="/"
                      className="nav-menulist"
                      onClick={() => handlerLogout()}
                    >
                      Sign Out
                    </Link>{" "}
                  </Nav.Link>
                )}
                {!isAuthenticated && (
                  <Nav.Link href="#" as={Nav}>
                    <Link to="/login" className="nav-menulist">
                      Log in
                    </Link>{" "}
                  </Nav.Link>
                )}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <AlertModal
        isShow={error.isShow}
        title={error.alert_title}
        text={error.alert_text}
        actionButtonTitle={error.alert_action_button_title}
        actionUrl={error.alert_action_url}
        message={error.message}
        onClose={onModalClosed}
      />
    </>
  );
};
