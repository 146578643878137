import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./checkout.css";
import { Col, Container, Row } from "react-bootstrap";
import TermsconditionsVIdeo from "../../assets/video/Man_walking_to_his_villa.mp4";
import { Link, useNavigate } from "react-router-dom";
import { getFormObj, server_domain } from "../../services/constants";
import { post } from "../../services/axios";
import { setError } from "../../redux/appsettingSlice";
import { cart } from "../../redux/cartSlice";
import { ConfirmModal } from "../../Components/ConfirmModal";
import { AutoVideo } from "../../Components/AutoVideo";
import { Agreements } from "../../Components/Agreements";
import { currencyFormat } from "../../services/helper";
import { BiCheckCircle } from "react-icons/bi";

const DOMAIN_STRING = "https://exceed.blueboxonline.com/";

const Checkout = () => {
  const user = useSelector((state) => state.auth.user);
  const user_id = user?.user_id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ordersItems, setOrdersItems] = useState([]);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [romoveOrderId, setRemoveOrderId] = useState("");
  const [credit, setCredit] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [creditMessage, setCreditMessage] = useState("");
  const [orderCompleteMessage, setOrderCompleteMessage] = useState('')

  useEffect(() => {
    getOrdersItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitError = (response) => {
    dispatch(
      setError({
        isShow: true,
        alert_title: response.data.alert_title,
        alert_text: response.data.alert_text,
        alert_action_button_title:
          response.data.alert_action_button_title,
        alert_action_url: response.data.alert_action_url,
        message: response.data.message,
      })
    );
  }
  
  const submitFailed = (err) => {
    dispatch(
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      })
    );
  }

  const getOrdersItems = async () => {
    let formData = getFormObj();
    formData.append("api_method", "get_cart");
    formData.append("user_id", user_id);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        const ordersItems = response.data.cart.items || [];
        setOrdersItems(ordersItems);
        setCredit(response.data.cart.credit);
        setTotalAmount(response.data.cart.amount_to_pay);
        setCreditMessage(response.data.cart.credit_message)
        dispatch(
          cart({items: ordersItems})
        );
        checkCart(ordersItems);
      } else if (response.data.error) {
        submitError(response);
      }
    } catch (err) {
      submitFailed(err)
    }
  };

  const checkCart = (items) => {
    if (!items || items.length === 0) {
      navigate("/");
    }
  }

  const setOrderItemQty = (e, orderId) => {
    return
    /**
     * Make the QTY uneditable
     * 
    const newOrders = ordersItems.map((orderItem) => {
      if (orderItem.numeric_id === orderId) {
        const qty = e.target.value ? e.target.value : "0";
        return { ...orderItem, qtyNUM: qty };
      }
      return orderItem;
    });
    setOrdersItems(newOrders);
    dispatch(
      cart({items: newOrders})
    );
    */
  };

  const handlerBlur = async (orderId) => {
    return
    /**
     * Make the QTY uneditable
     * 
    const order = ordersItems.find(
      (orderItem) => orderItem.numeric_id === orderId
    );

    let formData = getFormObj();
    formData.append("api_method", "update_app_orders_items");
    formData.append("user_id", user_id);
    formData.append("_id", orderId);
    formData.append(
      "itemISbb_esp_supplied_itemsID",
      order.itemISbb_esp_supplied_itemsID
    );
    formData.append("qtyNUM", order.qtyNUM);
    formData.append("priceNUM", order.priceNUM);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
      } else if (response.data.error) {
        submitError(response);
      }
    } catch (err) {
      submitFailed(err)
    }
    */
  };

  const callCapturePayment = async (stripe_ref, cart_amount) => {
        
    let formData = getFormObj();
    formData.append("api_method", "capture_payment");
    formData.append("user_id", user_id);
    formData.append("stripe_ref", stripe_ref);
    formData.append("amount", cart_amount);

    try {
        const response = await post(server_domain, formData);
        if (response.data.message === "SUCCESS") {
            setOrderCompleteMessage(response.data.order_complete);
            dispatch(
              cart({items: []})
            );            
        } else if (response.data.error) {
          submitError(response);          
        }
    } catch (err) {
        submitFailed(err)        
    }
  }

  const onPayZero = () => {
    callCapturePayment('Credit', 0)
  }

  const handlerContinueShopping = () => {
    navigate(-2);
  };

  const handlerRemove = (orderId) => {
    setRemoveOrderId(orderId);
    setIsRemoveModal(true);
  };

  const handlerCloseRemoveModal = async (val) => {
    let formData = getFormObj();
    formData.append("api_method", "delete_app_orders_items");
    formData.append("user_id", user_id);
    formData.append("_id", romoveOrderId);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        setIsRemoveModal(false);
        getOrdersItems();
      } else if (response.data.error) {
        submitError(response);
      }
    } catch (err) {
      submitFailed(err)
    }
  };

  return (
    <>
      {orderCompleteMessage ? (
        <div>
          <div className="video-term reveal fade-in active">
            <AutoVideo className="headervideo" src={TermsconditionsVIdeo}/>
          </div>
          
          <div className='position-relative payment-completed'>
            <Container className='mt-5'>
              <div className='header-maindiv'>
                <div className='header-payment'>
                  <div className='icon'>
                    <BiCheckCircle size="80" color='#eed996'/>
                  </div>
                  <h1 className='title'>Payment Complete</h1>
                </div>
              </div>
              <div className='confirm-detail' dangerouslySetInnerHTML={{ __html: orderCompleteMessage }}></div>
            </Container>
          </div>
        </div>
      ) : (
        <div>
          <div className="video-term reveal fade-in active">
            <AutoVideo className="headervideo" src={TermsconditionsVIdeo}/>
          </div>
          <div className="position-relative">
            <Container>
              <div className="header-maindiv">
                <h1 className="header-payment">Checkout</h1>
                <button className="continue-shop" onClick={handlerContinueShopping}>
                  Continue Shopping
                </button>
              </div>
              <Row className="top-row">
                <Col xs="2" lg="3"></Col>
                <Col xs="4" lg="6"></Col>
                <Col xs="2" lg="1"></Col>
                <Col xs="2" lg="1">Price</Col>
                <Col xs="2" lg="1">Total</Col>
              </Row>
              {ordersItems.map((orderItem) => (
                <Row className="checkout-row" key={orderItem.numeric_id}>
                  <Col xs="2" lg="3">
                    <Link
                      to={`/item/${orderItem?.itemISbb_esp_supplied_itemsID}`}
                    >
                      <div className="checkout-image">
                        <img
                          src={
                            DOMAIN_STRING +
                            orderItem?.image01ISfile
                          }
                          style={{
                            maxWidth: "200px",
                            maxHeight: "150px",
                            objectFit: "cover",
                          }}
                          alt="Product Checkout"
                        />
                      </div>
                    </Link>
                  </Col>
                  <Col xs="4" lg="6">
                    <div>
                      <Link
                        to={`/item/${orderItem?.itemISbb_esp_supplied_itemsID}`}
                      >
                        <h3 className="checkout-title">
                          {orderItem?.item_name}
                        </h3>
                      </Link>
                      {/* <p>
                        {orderItem?.itemISbb_esp_supplied_itemsID_data?.description}
                      </p> */}
                      <p className="checkout-people">
                        {parseInt(
                            orderItem?.no_of_peopleNUM
                          ) > 1
                          ? (<span>{`${parseInt(orderItem?.no_of_peopleNUM)} people`}</span>)
                          : (<span>{`${parseInt(orderItem?.no_of_peopleNUM)} person`}</span>)
                        }
                      </p>
                    </div>
                  </Col>
                  <Col xs="2" lg="1" className="count">
                    <div className="product-count">
                      <input
                        type="text"
                        placeholder="0"
                        value={parseInt(orderItem.qtyNUM)}
                        onChange={(e) => setOrderItemQty(e, orderItem.numeric_id)}
                        onBlur={() => handlerBlur(orderItem.numeric_id)}
                        readOnly= {true}
                      />
                      <button
                        className="remove-btn"
                        onClick={() => handlerRemove(orderItem.numeric_id)}
                      >
                        Remove
                      </button>
                    </div>
                  </Col>
                  <Col xs="2" lg="1" className="list">
                    <div>
                      <p className="price-list">
                        {currencyFormat(parseFloat(orderItem.priceNUM))}
                      </p>
                    </div>
                  </Col>
                  <Col xs="2" lg="1" className="total">
                    <div>
                      <p className="price-total">
                        {
                          currencyFormat(parseFloat(orderItem.priceNUM) * parseInt(orderItem.qtyNUM))
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              ))}
              <Row>
                <Col xs="2" lg="3"></Col>
                <Col xs="4" lg="6"></Col>
                <Col xs="2" lg="1"></Col>
                <Col xs="1" lg="1"></Col>
              </Row>
              {credit > 0 && (
                <Row className="d-flex justify-content-end">
                  <Col className="main-total">
                    <p className="price-totallast text-align-right">
                      Credit: {currencyFormat(credit)}
                    </p>              
                  </Col>
                </Row>
              )}
              <Row className="d-flex justify-content-end">
                <Col className="main-total">              
                  <p className="price-totallast">
                  Amount to pay: {currencyFormat(totalAmount)}
                  </p>
                  {(totalAmount > 0) ? (
                    <Link to="/payment">
                      <button className="cont-shop">Pay</button>
                    </Link>
                  ) : (
                    <button className="cont-shop" onClick={onPayZero}>Pay</button>
                  )}
                  
                </Col>
              </Row>
              {creditMessage && (
                <Row className="d-flex justify-content-end">
                  <Col className="bottom-agreements">
                    {creditMessage}
                  </Col>
                </Row>
              )}
              <Row className="d-flex justify-content-end">
                <Agreements />
              </Row>
            </Container>
          </div>
          <ConfirmModal
            isShow={isRemoveModal}
            onClose={(val) => handlerCloseRemoveModal(val)}
            title="Remove Item?"
            text="Are you sure you want to remove this item from your basket?"
          />
        </div>
      )}
    </>  
    
  );
};

export default Checkout;
