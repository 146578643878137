import React from "react";
import { useSelector } from "react-redux";
import "./termsconditions.css";
import "../../index.css";
import { Col, Container, Row } from "react-bootstrap";
import { AutoVideo } from "../../Components/AutoVideo";
import { htmlString } from "../../services/helper";

const TermsConditions = () => {
  const termsContent = useSelector((state) => state.appsetting.terms_content);
  const name = termsContent.name;
  const headerVideo = termsContent.header_videoISfile;
  const mainSubHeading = termsContent.main_subheading;
  const mainText = htmlString(termsContent.main_textISsmallplaintextbox);
  const secondSubHeading = termsContent.secondary_subheading;
  const secondText = htmlString(termsContent.secondary_textISsmallplaintextbox);
  const image1 = termsContent.image01ISfile;
  const image2 = termsContent.image02ISfile;
  const image3 = termsContent.image03ISfile;

  const reveal = () => {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  };
  reveal();
  window.addEventListener("scroll", reveal);
  return (
    <div>
      <div className="video-term reveal fade-in active">
        <AutoVideo className="headervideo" src={headerVideo}/>
      </div>
      <div className="position-relative">
        <Container className="pt-5">
          <div className="header-wrapper">
            <h1 className="header-text">{name}</h1>
          </div>
          <Row className="justify-content-md-center mt-5">
            <Col lg="8">
              <div className="first-row">
                <div className="maindiv-image">
                  {image1 && (
                    <div className="image-div mb-4">
                      <img src={image1} alt="image1" />
                    </div>
                  )}
                  {image2 && (
                    <div className="image-div mb-4">
                      <img src={image2} alt="image2" />
                    </div>
                  )}
                </div>
                <div className="image-text">
                  <h1>{mainSubHeading}</h1>
                  <div
                    dangerouslySetInnerHTML={{__html: mainText}}
                  />
                </div>
              </div>
            </Col>
            <Col lg="4">
              <div className="">
                <div className="">
                  {image3 && (
                    <div className="side-blog">
                      <img src={image3} alt="image3" />
                    </div>
                  )}
                </div>
                <div className="side-text">
                  <h1>{secondSubHeading}</h1>
                  <div
                    dangerouslySetInnerHTML={{__html: secondText}}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TermsConditions;
