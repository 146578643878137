import React from 'react'
import './order.css'
import { currencyFormat } from "../../../services/helper";
import { order_manage_link } from "../../../services/constants";

const Order = (props) => {
  const {
    items,
  } = props;
  const noOrders = "There are no orders in your account yet.";
  return (
    <>
      {items.length > 0 && (
        <>
          <div className='order-history order-sm-off'>
            <p className='date-order'>Date</p>
            <p className='date-order'>Order ID</p>
            <p className='item-order'>Item</p>
            <p className='date-order'>Qty</p>
            <p className='date-order'>Voucher</p>
            <p className='date-order'>Value</p>
            <p className='date-order'></p>
          </div>
          {items.map((item) => (
            <div className='order-history' key={item.key}>
              <p className='date-order date'><span className='titleorder-add'>Date</span>  {item.date}</p>
              <p className='date-order orderid'><span className='titleorder-add'>Order Id</span> #{item.orderId}</p>
              <p className='item-order item-title'>{item.name}</p>
              <p className='date-order code order-code'>{item.qty}</p>
              <p className='date-order code order-code'>{item.voucher}</p>
              <p className='date-order total-order'><span className='titleorder-add'>Total</span> {currencyFormat(item.value)}</p>
              <p className='date-order button-history sm-button-order'>
                <a href={`${order_manage_link}${item.voucherKey}`} target="_blank" rel="noreferrer">
                  <span className="badge buttony-gold text-dark">Manage</span>
                </a>
              </p>
            </div>
          ))}
        </>
      )}
      
      {items.length < 1 && (
        <div className='no-orders'>
          {noOrders}
        </div>
      )}
    </>
  )
}

export default Order