import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./saveitem.css";
import "../../index.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFormObj, server_domain } from "../../services/constants";
import { post } from "../../services/axios";
import { setError } from "../../redux/appsettingSlice";
import { favorite } from "../../redux/favoriteSlice";
import { currencyFormat } from "../../services/helper";

const SaveItem = () => {
  const [items, setItems] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [credit, setCredit] = useState(0);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const whitelabel_id = useSelector((state) => state.appsetting.whitelabel_id);
  const user_id = user?.user_id;

  const loadProfile = async () => {
    let formData = getFormObj();
    formData.append("api_method", "load_profile");
    formData.append("user_id", user_id);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        const { credit } = response.data.user;
        setCredit(credit);
      } else if (response.data.error) {
        submitError(response);
      }
    } catch (err) {
      submitFailed(err);
    }
  };

  const submitError = (response) => {
    dispatch(
      setError({
        isShow: true,
        alert_title: response.data.alert_title,
        alert_text: response.data.alert_text,
        alert_action_button_title: response.data.alert_action_button_title,
        alert_action_url: response.data.alert_action_url,
        message: response.data.message,
      })
    );
  };

  const submitFailed = (err) => {
    dispatch(
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      })
    );
  };

  const getFavorites = async () => {
    let formData = getFormObj();
    formData.append("api_method", "list_app_favorites");
    formData.append("user_id", user_id);
    try {
      const response = await post(server_domain, formData);
      if (
        response.data.message === "SUCCESS" &&
        response.data.list.length > 0
      ) {
        const favor = response.data.list;
        setFavorites(favor);
      }
    } catch (err) {
      submitFailed(err);
    }
  };

  const getItems = (fav) => {
    let newItems = [];
    fav.forEach(async (f, index) => {
      const id = f.itemISbb_esp_supplied_itemsID;
      const item = await getItem(id);
      if (item) {
        newItems = [...newItems, item];
        setItems(newItems);
      }
    });
  };

  const getItem = (id) => {
    let formData = getFormObj();
    formData.append("api_method", "get_item");
    formData.append("user_id", user_id);
    formData.append("item_id", id);
    formData.append("whitelabel_id", whitelabel_id);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await post(server_domain, formData);
        if (response.data.message === "SUCCESS") {
          resolve(response.data.item);
        }
      } catch (err) {
        reject(null);
      }
    });
  };

  const removeFavorite = async (favoriteId) => {
    let formData = getFormObj();
    formData.append("api_method", "delete_app_favorites");
    formData.append("user_id", user_id);
    formData.append("_id", favoriteId);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        updateItems(favoriteId);
      } else if (response.data.error) {
        submitError(response);
      }
    } catch (err) {
      submitFailed(err);
    }
  };

  const updateItems = (favId) => {
    const itemId = favorites.filter((f) => f._id === favId)[0]
      .itemISbb_esp_supplied_itemsID;
    const newItems = items.filter((i) => i._id !== itemId);
    setItems(newItems);
    dispatch(favorite({ counts: newItems.length }));
  };

  const onFavorite = (e, itemId) => {
    e.preventDefault();

    const favId = favorites.filter(
      (f) => f.itemISbb_esp_supplied_itemsID === itemId
    )[0]._id;
    removeFavorite(favId);
  };

  useEffect(() => {
    loadProfile();
    getFavorites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (favorites.length > 0) {
      getItems(favorites);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites]);

  return (
    <div>
      <div className="position-relative">
        <Container className="pt-5">
          <div className="header-wrapper no-video">
            <h1 className="header-text">Your Account</h1>
          </div>
          {credit > 0 && (
            <div className="credit-wrapper">
              <p className="credit">{`You have ${currencyFormat(
                credit
              )} credit in your account.`}</p>
            </div>
          )}
          <nav className="nav nav-borders">
            <Link to="/your-order" className="nav-link ms-0">
              Orders
            </Link>
            <Link to="/save-item" className="nav-link active ">
              Favourites
            </Link>
            <Link to="/user-profile" className="nav-link">
              Profile
            </Link>
          </nav>
          <hr className="mt-0 mb-4" />
          <Row className="justify-content-md-center mt-5">
            {items.map((i, index) => (
              <Col lg="6" className="" key={index}>
                <Link to={`/item/${i._id}`} className="details-row">
                  <div className="first-row">
                    <div className="maindiv-image">
                      <div className="image-div mb-3">
                        <img src={i.image01} alt={i.name} />
                        <div
                          className="favor"
                          onClick={(e) => onFavorite(e, i._id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill={"#ff0000"}
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="image-text">
                      <h3>{i.name}</h3>
                      <p
                        className="description"
                        dangerouslySetInnerHTML={{ __html: i.description }}
                      ></p>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
            {items.length < 1 && <div className="no-item">No Saved Items</div>}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SaveItem;
