export const server_domain = "https://exceed.blueboxonline.com/?api";
export const apikey = "jj3y8bc6fd9hp3WcE7Ui0244d1boleap";
export const apisecret = "lq34ebau467";

export const logo = "https://exceed.blueboxonline.com/portal/admhmhydrw/custom_modules/img/exceed_white.png"

export const arrowLeft = "https://exceed.blueboxonline.com/portal/admhmhydrw/custom_modules/img/left.png"
export const arrowRight = "https://exceed.blueboxonline.com/portal/admhmhydrw/custom_modules/img/right.png"

export const order_manage_link = "https://exceedprizes.com/?customer_redeem&token="

export const facebook_link = "https://www.facebook.com/theexceedgroup"
export const instagram_link = "https://www.instagram.com/theexceedgroup"
export const phone_number = "447887567369"
export const whatsapp_message = "Hello there"

export const bluebox_url = "http://www.blueboxonline.com"

export const getFormObj = () => {
  let formData = new FormData();
  formData.append("apikey", apikey);
  formData.append("apisecret", apisecret);

  return formData;
};
