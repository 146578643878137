import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./userprofile.css";
import "../../index.css";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFormObj, server_domain } from "../../services/constants";
import { post } from "../../services/axios";
import { setError } from "../../redux/appsettingSlice";
import { currencyFormat } from "../../services/helper";

const UserProfile = () => {
  const user = useSelector((state) => state.auth.user);
  const user_id = user?.user_id;
  const charities = useSelector((state) => state.appsetting.charities);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [card, setCard] = useState("None");
  const [charityID, setCharityID] = useState(null);

  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [charityMessage, setCharityMessage] = useState("");
  const [isCharitySuccess, setIsCharitySuccess] = useState(false);
  const [credit, setCredit] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    loadProfile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearMessage = () => {
    setMessage('');
  }

  const clearCharityMessage = () => {
    setCharityMessage('');
  }

  const submitError = (response) => {
    dispatch(
      setError({
        isShow: true,
        alert_title: response.data.alert_title,
        alert_text: response.data.alert_text,
        alert_action_button_title:
          response.data.alert_action_button_title,
        alert_action_url: response.data.alert_action_url,
        message: response.data.message,
      })
    );
  }
  
  const submitFailed = (err) => {
    dispatch(
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      })
    );
  }

  const loadProfile = async () => {
    let formData = getFormObj();
    formData.append("api_method", "load_profile");
    formData.append("user_id", user_id);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        const { first_name, last_name, email, telephone, company_name, card, charityISbb_esp_charitiesID, credit } = response.data.user;
        setFirstName(first_name);
        setLastName(last_name);
        setCompany(company_name);
        setPhone(telephone);
        setEmail(email);
        setCard(card);
        setCharityID(charityISbb_esp_charitiesID);
        setCredit(credit);
      } else if (response.data.error) {
        submitError(response);
      }
    } catch (err) {
      submitFailed(err);
    }
  };

  const updateCharity = async (id) => {
    let formData = getFormObj();
    formData.append("api_method", "update_profile");
    formData.append("user_id", user_id);    
    formData.append("charityISbb_esp_charitiesID", id);

    try {
      const response = await post(server_domain, formData);

      if (response.data.success === 1) {
        setCharityID(id)
        setCharityMessage("Charity has been updated successfully.")
        setIsCharitySuccess(true);
      } else if (response.data.error) {
        setCharityMessage("Updating charity has been failed.")
        setIsCharitySuccess(false);
      }
    } catch (err) {      
      dispatch(
        setError({
          isShow: true,
          alert_title: "Error",
          alert_text: "",
          alert_action_button_title: "",
          alert_action_url: "",
          message: err.toString(),
        })
      );
    }
    setTimeout(() => {
      clearCharityMessage();
    }, 3000)
  }

  const handlerSubmit = async () => {
    let formData = getFormObj();
    formData.append("api_method", "update_profile");
    formData.append("user_id", user_id);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("company_name", company);
    formData.append("email", email);
    formData.append("telephone", phone);

    try {
      const response = await post(server_domain, formData);

      if (response.data.success === 1) {
        setMessage("Profile has been updated successfully.")
        setIsSuccess(true);
      } else if (response.data.error) {
        setMessage("Updating profile has been failed.")
        setIsSuccess(false);
      }
    } catch (err) {      
      dispatch(
        setError({
          isShow: true,
          alert_title: "Error",
          alert_text: "",
          alert_action_button_title: "",
          alert_action_url: "",
          message: err.toString(),
        })
      );
    }
    setTimeout(() => {
      clearMessage();
    }, 3000)
  };

  const forgetCard = async () => {
    if (card === 'None') {
      return;
    }
    let formData = getFormObj();
    formData.append("api_method", "forget_card");
    formData.append("user_id", user_id);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        loadProfile();
      } else if (response.data.error) {
        submitError(response);
      }
    } catch (err) {
      submitFailed(err);
    }
  }

  const resetPassword = async () => {
    let formData = getFormObj();
    formData.append("api_method", "password_reset");
    formData.append("email", email);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        dispatch(
          setError({
            isShow: true,
            alert_title: "Success",
            alert_text: "",
            alert_action_button_title: "",
            alert_action_url: "",
            message: "Thanks, we've sent you an email with a link to reset your password.",
          })
        );
      } else if (response.data.error) {
        submitError(response);
      }
    } catch (err) {
      submitFailed(err);
    }
  }

  return (
    <div>
      <div className="position-relative">
        <Container className="pt-5">
          <div className="header-wrapper no-video">
            <h1 className="header-text">Your Account</h1>
          </div>
          {credit > 0 && (
            <div className="credit-wrapper">
              <p className="credit">{`You have ${currencyFormat(credit)} credit in your account.`}</p>
            </div>
          )}
          <nav className="nav nav-borders">
            <Link to="/your-order" className="nav-link ms-0">
              Orders
            </Link>
            <Link to="/save-item" className="nav-link">
              Favourites
            </Link>
            <Link to="/user-profile" className="nav-link active">
              Profile
            </Link>
          </nav>
          <hr className="mt-0 mb-4" />
          <div className="row">
            <div className="col-xl-8">
              <div className="card mb-4">
                <div className="card-header">Account Details</div>
                {message !=="" && (
                  <div className={isSuccess ? "card-header text-success" : "card-header text-danger"}>{message}</div>
                )}
                <div className="card-body">
                  <form>
                    <div className="row gx-3 mb-3">
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputFirstName">
                          First name
                        </label>
                        <input
                          className="form-control"
                          id="inputFirstName"
                          type="text"
                          placeholder="Enter your first name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputLastName">
                          Last name
                        </label>
                        <input
                          className="form-control"
                          id="inputLastName"
                          type="text"
                          placeholder="Enter your last name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row gx-3 mb-3">
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputOrgName">
                          Company name
                        </label>
                        <input
                          className="form-control"
                          id="inputOrgName"
                          type="text"
                          placeholder="Enter your organization name"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputPhone">
                          Phone number
                        </label>
                        <input
                          className="form-control"
                          id="inputPhone"
                          type="tel"
                          placeholder="Enter your phone number"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="small mb-1" htmlFor="inputEmailAddress">
                        Email address
                      </label>
                      <input
                        className="form-control"
                        id="inputEmailAddress"
                        type="email"
                        placeholder="Enter your email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <button
                      className="btn button-gold"
                      type="button"
                      onClick={handlerSubmit}
                    >
                      Save changes
                    </button>
                  </form>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-body">
                  <form>
                    <div className="mb-3">
                      <label className="small mb-1" htmlFor="selectCharity">
                        Charity
                      </label>
                      <select
                        name="cars"
                        id="selectCharity"
                        className="form-control select"
                        onChange={(e) => updateCharity(e.target.value)}
                        value={charityID ? charityID : 'DEFAULT'}
                      >
                        <option value='DEFAULT' disabled>--Please choose a charity--</option>
                        {charities.map((charity, index) => (
                          <option value={charity._id} key={index}>{ charity.name }</option>
                        ))}
                      </select>
                    </div>
                  </form>
                  {charityMessage !=="" && (
                    <div className={isCharitySuccess ? "card-header text-success" : "card-header text-danger"}>{charityMessage}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card mb-4 mb-xl-0 p-4">
                <div>
                  <button
                    className="btn button-gold"
                    onClick={resetPassword}
                  >
                    Reset Password
                  </button>
                </div>
                <div className="saved-card">
                  Saved Card
                  <br />
                  VISA ending {card}
                  <br />
                  {card ==="None" && (
                    <button 
                      className="btn button-gold mt-3"
                      onClick={forgetCard}
                    >
                      Forget Card
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default UserProfile;
