import React from "react";
import "./Agreements.css";

export const Agreements = () => {
  const terms_link = '/terms-conditions'
  const privacy_link = '/privacy-policy'
  
  return (
    <div className="bottom-agreements">
      By using this app you agree to our <a href={terms_link} rel="noreferrer">Terms & Conditions</a> and our <a href={privacy_link} rel="noreferrer">Privacy Policy</a>
    </div>
  );
};
