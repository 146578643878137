import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./productdetail.css";
import { Col, Container, Row, Carousel } from "react-bootstrap";
import Rating from "../../assets/image/rating.png";
import { useParams, useNavigate } from "react-router-dom";
import { getFormObj, server_domain } from "../../services/constants";
import { post } from "../../services/axios";
import { setError } from "../../redux/appsettingSlice";
import { favorite } from "../../redux/favoriteSlice";
import { Swipeable, defineSwipe } from "react-touch";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { currencyFormat } from "../../services/helper";

const ProductDetail = () => {
  const params = useParams();
  const itemId = params.id;
  const [newItem, setNewItem] = useState();
  const user = useSelector((state) => state.auth.user);
  const whitelabel_id = useSelector((state) => state.appsetting.whitelabel_id);
  const user_id = user?.user_id;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [item, setItem] = useState([]);
  const [itemImages, setItemImages] = useState([]);
  const [favorites, setFavorites] = useState([]);

  const swipe = defineSwipe({ swipeDistance: 10 });

  const swipeLeft = () => {
    navigate(`/item/${item.previous_id}`);
    setNewItem(item.previous_id);
  };

  const swipeRight = () => {
    navigate(`/item/${item.next_id}`);
    setNewItem(item.next_id);
  };

  const onGoBack = () => {
    navigate(`/category/${item.category_id}`);
  };

  useEffect(() => {
    const getItems = async () => {
      let formData = getFormObj();
      formData.append("api_method", "get_item");
      formData.append("user_id", user_id);
      formData.append("item_id", itemId);
      formData.append("whitelabel_id", whitelabel_id);

      try {
        const response = await post(server_domain, formData);
        if (response.data.message === "SUCCESS") {
          setItem(response.data.item);
          let images = [];
          if (response.data.item.image01) {
            images = [...images, response.data.item.image01];
          }
          if (response.data.item.image02) {
            images = [...images, response.data.item.image02];
          }
          if (response.data.item.image03) {
            images = [...images, response.data.item.image03];
          }
          setItemImages(images);
        } else if (response.data.error) {
          dispatch(
            setError({
              isShow: true,
              alert_title: response.data.alert_title,
              alert_text: response.data.alert_text,
              alert_action_button_title:
                response.data.alert_action_button_title,
              alert_action_url: response.data.alert_action_url,
              message: response.data.message,
            })
          );
        }
      } catch (err) {
        setError({
          isShow: true,
          alert_title: "Error",
          alert_text: "",
          alert_action_button_title: "",
          alert_action_url: "",
          message: err.toString(),
        });
      }
    };

    getItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItem]);

  useEffect(() => {
    if (item._id) {
      getFavorites();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item._id]);

  const handlerBuyNow = async () => {
    let formData = getFormObj();
    formData.append("api_method", "add_app_orders_items");
    formData.append("user_id", user_id);
    formData.append("itemISbb_esp_supplied_itemsID", itemId);
    formData.append("qtyNUM", "1");
    formData.append("priceNUM", item.typically_sells_for);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        navigate("/checkout");
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            alert_title: response.data.alert_title,
            alert_text: response.data.alert_text,
            alert_action_button_title: response.data.alert_action_button_title,
            alert_action_url: response.data.alert_action_url,
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      });
    }
  };

  const getFavorites = async () => {
    let formData = getFormObj();
    formData.append("api_method", "list_app_favorites");
    formData.append("user_id", user_id);

    try {
      const response = await post(server_domain, formData);
      if (
        response.data.message === "SUCCESS" &&
        response.data.list.length > 0
      ) {
        const favor = response.data.list;
        setFavorites(favor);
        updateItems(favor);
      }
    } catch (err) {
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      });
    }
  };

  const addFavorite = async (productId) => {
    let formData = getFormObj();
    formData.append("api_method", "add_app_favorites");
    formData.append("user_id", user_id);
    formData.append("itemISbb_esp_supplied_itemsID", productId);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        const favor = [...favorites, response.data.item];
        setFavorites(favor);
        updateItems(favor);
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            alert_title: response.data.alert_title,
            alert_text: response.data.alert_text,
            alert_action_button_title: response.data.alert_action_button_title,
            alert_action_url: response.data.alert_action_url,
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      });
    }
  };

  const removeFavorite = async (favoriteId) => {
    let formData = getFormObj();
    formData.append("api_method", "delete_app_favorites");
    formData.append("user_id", user_id);
    formData.append("_id", favoriteId);

    try {
      const response = await post(server_domain, formData);
      if (response.data.message === "SUCCESS") {
        const favor = favorites.filter((item) => item._id !== favoriteId);
        setFavorites(favor);
        updateItems(favor);
      } else if (response.data.error) {
        dispatch(
          setError({
            isShow: true,
            alert_title: response.data.alert_title,
            alert_text: response.data.alert_text,
            alert_action_button_title: response.data.alert_action_button_title,
            alert_action_url: response.data.alert_action_url,
            message: response.data.message,
          })
        );
      }
    } catch (err) {
      setError({
        isShow: true,
        alert_title: "Error",
        alert_text: "",
        alert_action_button_title: "",
        alert_action_url: "",
        message: err.toString(),
      });
    }
  };

  const updateItems = (favors) => {
    let tempItem = item;
    if (favors.length > 0) {
      let temp = null;
      favors.forEach((favor) => {
        if (item._id === favor.itemISbb_esp_supplied_itemsID) {
          temp = favor._id;
        }
      });
      item.itemISbb_esp_supplied_itemsID = temp;
    } else {
      item.itemISbb_esp_supplied_itemsID = null;
    }
    setItem(tempItem);
    let images = [];
    if (tempItem.image01) {
      images = [...images, tempItem.image01];
    }
    if (tempItem.image02) {
      images = [...images, tempItem.image02];
    }
    if (tempItem.image03) {
      images = [...images, tempItem.image03];
    }
    setItemImages(images);

    dispatch(favorite({ counts: favors.length }));
  };

  const onFavorite = (e, favoriteId, productId) => {
    e.preventDefault();
    if (favoriteId) {
      removeFavorite(favoriteId);
    } else {
      addFavorite(productId);
    }
  };

  return (
    <div>
      <div className="product-nav">
        <div className="py-1">
          <div className="item-nav">
            <div>
              <div className="arrows">
                <div className="left arrow" onClick={swipeLeft}>
                  {item.previous_id && (
                    <Swipeable config={swipe} onSwipeLeft={swipeLeft}>
                      <div className="d-flex justify-content-center align-items-center">
                        <BiLeftArrowAlt size={"30px"} color={"#eed996"} />
                        <span>Prev</span>
                      </div>
                    </Swipeable>
                  )}
                </div>
                <div
                  className="back arrow d-flex justify-content-center align-items-center"
                  onClick={onGoBack}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <span>Back</span>
                  </div>
                </div>
                <div className="right arrow" onClick={swipeRight}>
                  {item.next_id && (
                    <Swipeable config={swipe} onSwipeRight={swipeRight}>
                      <div className="d-flex justify-content-center align-items-center">
                        <span>Next</span>
                        <BiRightArrowAlt size={"30px"} color={"#eed996"} />
                      </div>
                    </Swipeable>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="slider-product">
        <Carousel
          fade
          controls={itemImages.length > 1}
          indicators={itemImages.length > 1}
        >
          {itemImages.map((i, index) => (
            <Carousel.Item key={index}>
              <img className="d-block w-100" src={i} alt={`Slide ${index}`} />
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="product-info">
          <Container className="mt-3">
            <Row>
              <Col>
                <div>
                  <p className="price">
                    {currencyFormat(item.typically_sells_for)}
                  </p>
                  <p className="people">
                    {item.no_of_people}{" "}
                    {item.no_of_people === "1" ? "person" : "people"}
                  </p>
                  <div className="map">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.851905223259!2d-0.0945627!3d51.497585099999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604a097011091%3A0xc1b8da219324a789!2s4%20Brockham%20St%2C%20London%20SE1%204HX%2C%20UK!5e0!3m2!1sen!2sus!4v1652538531478!5m2!1sen!2sus"
                      width="220"
                      height="200"
                      referrerPolicy="no-referrer-when-downgrade"
                      title="map"
                    ></iframe>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="d-flex justify-content-end align-items-center">
                  <div className="favorite-wrapper me-3">
                    <div
                      onClick={(e) =>
                        onFavorite(
                          e,
                          item.itemISbb_esp_supplied_itemsID,
                          item._id
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill={
                          item.itemISbb_esp_supplied_itemsID
                            ? "#ff0000"
                            : "#ffffff"
                        }
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
                      </svg>
                    </div>
                  </div>
                  <div className="buynow-maindiv">
                    <button className="buy-now" onClick={handlerBuyNow}>
                      Buy Now
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div>
        <Container className="mt-3">
          <Row className="sm-detail">
            <Col lg="3" className="price-sec"></Col>
            <Col lg="6" className="title-last">
              <div>
                <h1 className="product-title">{item.name}</h1>
                <div className="rating-star">
                  <img src={Rating} alt="Rating star" />
                </div>
                <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
              </div>
            </Col>
            <Col lg="3" className="mt-4 button-top"></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ProductDetail;
