import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import './contactus.css'
import '../../index.css'
import { Container, Row } from 'react-bootstrap'
import Whatsapp from "../../assets/image/whatsapp.png"
import { phone_number, whatsapp_message, getFormObj, server_domain } from "../../services/constants"
import { setError } from "../../redux/appsettingSlice";
import { post } from "../../services/axios";
import ReactWhatsapp from 'react-whatsapp'

const Contactus = () => {
    const contactUsContent = useSelector(
        (state) => state.appsetting.contact_us_content
    );
    const name = contactUsContent.name;
    const mainText = contactUsContent.main_textISsmallplaintextbox
    const secondaryText = contactUsContent.secondary_textISsmallplaintextbox

    const dispatch = useDispatch();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [telephone, setTelephone] = useState("");
    const [message, setMessage] = useState("");
    const [formError, setFormError] = useState("");
    

    const reveal = () => {
        var reveals = document.querySelectorAll(".reveal");
        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;
            if (elementTop < (windowHeight - elementVisible)) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    const handlerSubmit = async (e) => {
        e.preventDefault();

        console.log(firstName, lastName, email, telephone, message)
        if (!(firstName && lastName && email && telephone)) {
            setFormError("Please fill out all of the fields to submit.")
            return
        }
    
        let formData = getFormObj();
        formData.append("api_method", "contact_form");
        formData.append("first_name", firstName);
        formData.append("last_name", lastName);
        formData.append("email", email);
        formData.append("telephone", telephone);
        formData.append("message", message);
    
        try {
          const contactResponse = await post(server_domain, formData);
    
          if (contactResponse.data.success === 1) {
            dispatch(
              setError({
                isShow: true,
                alert_title: contactResponse.data.alert_title,
                alert_text: contactResponse.data.alert_text,
                alert_action_button_title: "",
                alert_action_url: "",
                message: contactResponse.data.message,
              })
            );
          } else if (contactResponse.data.error) {
            dispatch(
              setError({
                isShow: true,
                alert_title: contactResponse.data.alert_title,
                alert_text: contactResponse.data.alert_text,
                alert_action_button_title: "",
                alert_action_url: "",
                message: contactResponse.data.message,
              })
            );
          }
        } catch (err) {
          dispatch(
            setError({
              isShow: true,
              alert_title: "Error",
              alert_text: "",
              alert_action_button_title: "",
              alert_action_url: "",
              message: err.toString(),
            })
          );
        }
    };
    reveal()
    window.addEventListener("scroll", reveal);
    return (
        <div>
            <div className='position-relative'>
                <Container className='pt-5'>                    
                    <div className="header-wrapper no-video">
                        <h1 className="header-text">{name}</h1>
                    </div>
                    <Row className="justify-content-md-center mt-5">
                        <div className="container">
                            <div className="innerwrap">
                                <section className="section2 clearfix">
                                    <div className="col2 column2 first">
                                        <div className="sec2contactform">
                                            <h3 className="sec2frmtitle">{mainText}</h3>
                                            {formError && (
                                                <div className="formError text-danger">{formError}</div>
                                            )}
                                            <form onSubmit={handlerSubmit}>
                                                <div className="clearfix">
                                                    <input
                                                        className="col2 first"
                                                        type="text"
                                                        value={firstName}
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        onFocus={() => setFormError("")}
                                                        placeholder="FirstName"
                                                    />
                                                    <input
                                                        className="col2 last"
                                                        type="text"
                                                        value={lastName}
                                                        onChange={(e) => setLastName(e.target.value)}
                                                        onFocus={() => setFormError("")}
                                                        placeholder="LastName"
                                                    />
                                                </div>
                                                <div className="clearfix">
                                                    <input
                                                        className="col2 first"
                                                        type="Email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        onFocus={() => setFormError("")}
                                                        placeholder="Email"
                                                    />
                                                    <input
                                                        className="col2 last"
                                                        type="text"
                                                        value={telephone}
                                                        onChange={(e) => setTelephone(e.target.value)}
                                                        onFocus={() => setFormError("")}
                                                        placeholder="Contact Number"
                                                    />
                                                </div>
                                                <div className="clearfix">
                                                    <textarea
                                                        name="textarea"
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        onFocus={() => setFormError("")}
                                                        id="" cols="30" rows="1"
                                                        placeholder='Your message here...'
                                                    ></textarea>
                                                </div>
                                                <div className="clearfix"><input type="submit" value="Send" /></div>
                                            </form>
                                        </div>

                                    </div>
                                    <div className="col2 column1 last">
                                        <div className="sec2innercont">
                                            <div className="sec2addr">
                                                <p>{secondaryText}</p>
                                                {/* <p><span className="collig">Address :</span> 4 Brockham St, London SE1 4HX, UK</p>
                                                <p><span className="collig">Phone :</span> +00 000 000 0000</p>
                                                <p><span className="collig">Email :</span> test@gmail.com</p>
                                                <p><span className="collig">Fax :</span> +00 000 000 0000</p> */}
                                                <p className='social-icon whatsapp-icon'>
                                                    <ReactWhatsapp number={phone_number} message={whatsapp_message} className="whatsapp">
                                                        <li>
                                                            <img src={Whatsapp} alt="Whatsapp"/>
                                                        </li>
                                                    </ReactWhatsapp>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Contactus